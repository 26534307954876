import React from 'react'

function Empty() {
  return (
    <> 
    <div className='flex body-font font-[Open Sans] font-light'>
    <div className='mt-[1.8em] ml-auto mr-auto w-[60vw]'>
      <p
      className='mb-2 lg:text-[1.55em] md:text-[1.3em] sm:text-[1.3em] 
      text-[1.3em] bg-inherit drop-shadow-sm'
      >
          Sprints
      </p>

      <div className='w-[60vw] h-[10em]
      rounded-md border-dashed
      border-[#d4d4d4] border-[0.1em]'
      >
      <div className='text-center translate-y-[300%]'> 
          <p className='text-[#6a6a6a] 
          font-lato font-normal text-[0.9em]'>
                Empty 
          </p>
      </div>
      </div>
      </div>
      </div>
  </>
  )
}

export default Empty
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './App/AppRouter';

function App() {
  return (
    <Router basename='/'>
      <AppRouter/> 
    </Router>
  );
}

export default App;

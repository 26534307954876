
import React from 'react'
import { SprintContexts } from '../../../contexts/SprintContexts'
import Empty from './Empty';
import SprintList from './SprintList';
import { IssueContexts } from '../../../contexts/IssueContexts';
import { AiOutlinePlus } from 'react-icons/ai'
import { useStateContext } from '../../../contexts/ContextProvider';

function SprintsContainer( props ) {

  const { id, items } = props

    const { 
        Sprints, 
        // setSprints,
        setNewSprintIssue 
    } = SprintContexts();
    // const { SelectedProj } = ProjContexts();
    const { setIssueModal } = IssueContexts();
    const { nav, ProjectNav, ScreenWidth} = useStateContext();

    // useEffect( () => { 
    //   if (!Sprints?.length || 
    //     Sprints[0]?.project !== SelectedProj?._id) {
    //       axios.get(process.env.REACT_APP_API_Sprints, { withCredentials: true })
    //         .then( 
    //             res => {
    //                 if (!Array.isArray(res.data)) {
    //                     // console.log(res.data);
    //                     return;
    //                 }
    //                 setSprints(
    //                   res.data
    //                     ?.filter(
    //                         sprint => 
    //                         (sprint.project === SelectedProj?._id) 
    //                     )
    //                   )
    //             })
    //           } // eslint-disable-next-line
    //     }, [SelectedProj])


  return (
  <> 
  {
  Sprints?.length ?
  <SprintList id={id} items={items}/>  : <Empty/>
  }

   <div className='flex body-font font-[Open Sans] subpixel-antialiased'>
      <div className='ml-auto mr-auto top-0 sticky'
        style={{
          width: !nav && !ProjectNav ? '70vw' : (nav && ProjectNav && ScreenWidth<1024) ? '42.5vw' : '60vw',
          transition: '0.1s'
          }}
      >
 
      <button className='mt-[0.6em] ml-[0.4em] flex items-center p-1 
      hover:bg-[#e2e2e2] rounded-[0.25em] ease-in-out duration-100'
      onClick={
        () => {
          setNewSprintIssue(true);
          setIssueModal(true);
        }
      }
      >
      <AiOutlinePlus 
      fontSize={'1.4em'}
      color='#505050'
      className='drop-shadow-sm'
      /> 
      <p className='font-normal text-[#505050] ml-1'> Create issue </p>
      </button>
    </div>
    </div>


   </>
  )
}

export default SprintsContainer